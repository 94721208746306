export const EnumAuthProvider = {
  PICTRIX: 'PICTRIX',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
} as const

export const EnumEmailTemplate = {
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  WELCOME: 'WELCOME',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESSFUL: 'RESET_PASSWORD_SUCCESSFUL',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  SUBSCRIPTION_CHANGED_FREE_TO_PAID: 'SUBSCRIPTION_CHANGED_FREE_TO_PAID',
  SUBSCRIPTION_CHANGED_PAID_TO_FREE: 'SUBSCRIPTION_CHANGED_PAID_TO_FREE',
  SUBSCRIPTION_CHANGED_PAID_TO_PAID_UPGRADE: 'SUBSCRIPTION_CHANGED_PAID_TO_PAID_UPGRADE',
  SUBSCRIPTION_CHANGED_PAID_TO_PAID_DOWNGRADE: 'SUBSCRIPTION_CHANGED_PAID_TO_PAID_DOWNGRADE',
  SUBSCRIPTION_CHANGED_PAID_TO_PAID_BILLING_PERIOD_CHANGED: 'SUBSCRIPTION_CHANGED_PAID_TO_PAID_BILLING_PERIOD_CHANGED',
  SUBSCRIPTION_RENEWED: 'SUBSCRIPTION_RENEWED',
} as const

export const EnumGender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
} as const

export const EnumImageGenerationStatus = {
  INITIALIZING: 'INITIALIZING',
  IN_QUEUE: 'IN_QUEUE',
  IN_PROGRESS: 'IN_PROGRESS',
  CONVERTING_TO_SVG: 'CONVERTING_TO_SVG',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
  TIMED_OUT: 'TIMED_OUT',
} as const

export const EnumImageGuideType = {
  EDGES: 'EDGES',
  SOFT_EDGES: 'SOFT_EDGES',
  DEPTH: 'DEPTH',
} as const

export const EnumImageType = {
  JPEG: 'JPEG',
  PNG: 'PNG',
  WEBP: 'WEBP',
  SVG: 'SVG',
} as const

export const EnumMembershipPeriod = {
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
} as const

export const EnumMembershipType = {
  LITE: 'LITE',
  PRO: 'PRO',
  PREMIUM: 'PREMIUM',
  ENTERPRISE: 'ENTERPRISE',
} as const

export const EnumOrientationType = {
  SQUARE: 'SQUARE',
  LANDSCAPE: 'LANDSCAPE',
  PORTRAIT: 'PORTRAIT',
} as const

export const EnumPurchaseType = {
  MEMBERSHIP: 'MEMBERSHIP',
  CREDITS: 'CREDITS',
} as const

export const EnumSize = {
  SMALL_SQUARE_512x512_1_1: 'SMALL_SQUARE_512x512_1_1',
  SMALL_LANDSCAPE_640x448_3_2: 'SMALL_LANDSCAPE_640x448_3_2',
  SMALL_PORTRAIT_448x640_2_3: 'SMALL_PORTRAIT_448x640_2_3',
  SMALL_LANDSCAPE_576x448_4_3: 'SMALL_LANDSCAPE_576x448_4_3',
  SMALL_PORTRAIT_448x576_3_4: 'SMALL_PORTRAIT_448x576_3_4',
  SMALL_LANDSCAPE_640x384_16_9: 'SMALL_LANDSCAPE_640x384_16_9',
  SMALL_PORTRAIT_384x640_9_16: 'SMALL_PORTRAIT_384x640_9_16',
  SMALL_LANDSCAPE_768x320_21_9: 'SMALL_LANDSCAPE_768x320_21_9',
  SMALL_PORTRAIT_320x768_9_21: 'SMALL_PORTRAIT_320x768_9_21',
  MEDIUM_SQUARE_1024x1024_1_1: 'MEDIUM_SQUARE_1024x1024_1_1',
  MEDIUM_LANDSCAPE_1216x832_3_2: 'MEDIUM_LANDSCAPE_1216x832_3_2',
  MEDIUM_PORTRAIT_832x1216_2_3: 'MEDIUM_PORTRAIT_832x1216_2_3',
  MEDIUM_LANDSCAPE_1152x896_4_3: 'MEDIUM_LANDSCAPE_1152x896_4_3',
  MEDIUM_PORTRAIT_896x1152_3_4: 'MEDIUM_PORTRAIT_896x1152_3_4',
  MEDIUM_LANDSCAPE_1344x768_16_9: 'MEDIUM_LANDSCAPE_1344x768_16_9',
  MEDIUM_PORTRAIT_768x1344_9_16: 'MEDIUM_PORTRAIT_768x1344_9_16',
  MEDIUM_LANDSCAPE_1536x640_21_9: 'MEDIUM_LANDSCAPE_1536x640_21_9',
  MEDIUM_PORTRAIT_640x1536_9_21: 'MEDIUM_PORTRAIT_640x1536_9_21',
  LARGE_SQUARE_1408x1408_1_1: 'LARGE_SQUARE_1408x1408_1_1',
  LARGE_LANDSCAPE_1728x1152_3_2: 'LARGE_LANDSCAPE_1728x1152_3_2',
  LARGE_PORTRAIT_1152x1728_2_3: 'LARGE_PORTRAIT_1152x1728_2_3',
  LARGE_LANDSCAPE_1664x1216_4_3: 'LARGE_LANDSCAPE_1664x1216_4_3',
  LARGE_PORTRAIT_1216x1664_3_4: 'LARGE_PORTRAIT_1216x1664_3_4',
  LARGE_LANDSCAPE_1920x1088_16_9: 'LARGE_LANDSCAPE_1920x1088_16_9',
  LARGE_PORTRAIT_1088x1920_9_16: 'LARGE_PORTRAIT_1088x1920_9_16',
  LARGE_LANDSCAPE_2176x960_21_9: 'LARGE_LANDSCAPE_2176x960_21_9',
  LARGE_PORTRAIT_960x2176_9_21: 'LARGE_PORTRAIT_960x2176_9_21',
} as const

export const EnumSizeType = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
} as const

export const EnumStyle = {
  DREAMSCAPE: 'DREAMSCAPE',
  DRIPPING: 'DRIPPING',
  DYSTOPIAN: 'DYSTOPIAN',
  EXPRESSIONIST: 'EXPRESSIONIST',
  FANTASY: 'FANTASY',
  FILM_NOIR: 'FILM_NOIR',
  FLAT: 'FLAT',
  FOOD: 'FOOD',
  FUTURISTIC: 'FUTURISTIC',
  GLAMOUR: 'GLAMOUR',
  GOTHIC: 'GOTHIC',
  GRAFFITI: 'GRAFFITI',
  HORROR: 'HORROR',
  ILLUSTRATION: 'ILLUSTRATION',
  IMPRESSIONIST: 'IMPRESSIONIST',
  ISOMETRIC: 'ISOMETRIC',
  KIRIGAMI: 'KIRIGAMI',
  LINE_ART: 'LINE_ART',
  LONG_EXPOSURE: 'LONG_EXPOSURE',
  LOWPOLY: 'LOWPOLY',
  MACABRE: 'MACABRE',
  MINECRAFT: 'MINECRAFT',
  ABSTRACT: 'ABSTRACT',
  ALIEN: 'ALIEN',
  ANALOG: 'ANALOG',
  ANIMATED: 'ANIMATED',
  ANIME: 'ANIME',
  ARCHITECTURAL: 'ARCHITECTURAL',
  BIOMECHANICAL: 'BIOMECHANICAL',
  CARTOON: 'CARTOON',
  CINEMATIC: 'CINEMATIC',
  COLLAGE: 'COLLAGE',
  COLORING_BOOK: 'COLORING_BOOK',
  COMIC_BOOK: 'COMIC_BOOK',
  CYBERNETIC: 'CYBERNETIC',
  DIGITAL_ART: 'DIGITAL_ART',
  DISCO: 'DISCO',
  MONOCHROME: 'MONOCHROME',
  NEON: 'NEON',
  ORIGAMI: 'ORIGAMI',
  PAPER_CUT: 'PAPER_CUT',
  PAPER_QUILL: 'PAPER_QUILL',
  PHOTOGRAPHIC: 'PHOTOGRAPHIC',
  PIXAR: 'PIXAR',
  PIXEL_ART: 'PIXEL_ART',
  POP_ART: 'POP_ART',
  PSYCHEDELIC: 'PSYCHEDELIC',
  RENAISSANCE: 'RENAISSANCE',
  RETRO_ARCADE: 'RETRO_ARCADE',
  SCI_FI: 'SCI_FI',
  SHADOWBOX: 'SHADOWBOX',
  SILHOUETTE: 'SILHOUETTE',
  SPACE: 'SPACE',
  SPLATTER: 'SPLATTER',
  STAINED_GLASS: 'STAINED_GLASS',
  TRIBAL: 'TRIBAL',
  TYPOGRAPHY: 'TYPOGRAPHY',
  WATERCOLOR: 'WATERCOLOR',
  NONE: 'NONE',
} as const

export const EnumStyleType = {
  PIXELS: 'PIXELS',
  SVG: 'SVG',
} as const

export const EnumVerifyType = {
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  RESET_PASSWORD: 'RESET_PASSWORD',
} as const
