'use client'

import * as React from 'react'
import { ThemeProvider } from 'next-themes'
import { Flowbite } from 'flowbite-react'
import { SnackbarProvider } from 'notistack'
import type { ClientInferResponses } from '@ts-rest/core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import StoreProvider from './StoreProvider'
import FlowbiteTheme from './theme/Flowbite'
import type { contract } from '../../../../../lib/backend/lib'
import { clientApiService } from '@/services/client-api-service'

interface Props {
  children: React.ReactNode
  initialData?: ClientInferResponses<typeof contract.getFrontendInitialData, 200>
}

const flowbiteTheme = { theme: FlowbiteTheme }

const snackbarRootClasses = [
  '[&>.notistack-MuiContent-default]:bg-neutral-700',
  '[&>.notistack-MuiContent-info]:bg-info',
  '[&>.notistack-MuiContent-success]:bg-success',
  '[&>.notistack-MuiContent-warning]:bg-warning',
  '[&>.notistack-MuiContent-error]:bg-danger',
  'dark:[&>.notistack-MuiContent-default]:bg-neutral-700',
  'dark:[&>.notistack-MuiContent-info]:bg-info-dark',
  'dark:[&>.notistack-MuiContent-success]:bg-success-dark',
  'dark:[&>.notistack-MuiContent-warning]:bg-warning-dark',
  'dark:[&>.notistack-MuiContent-error]:bg-danger-dark',
]

const snackbarStyles = {
  root: snackbarRootClasses.join(' '),
}

export default function App({ children, initialData }: Props): React.ReactElement {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
          },
        },
      }),
  )

  return (
    <ThemeProvider attribute="class">
      <Flowbite theme={flowbiteTheme}>
        <SnackbarProvider classes={snackbarStyles}>
          <QueryClientProvider client={queryClient}>
            <clientApiService.ReactQueryProvider>
              <StoreProvider initialData={initialData}>{children}</StoreProvider>
            </clientApiService.ReactQueryProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </Flowbite>
    </ThemeProvider>
  )
}
