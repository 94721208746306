import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getImages: {
      metadata: {
        openApiTags: ['Getters'],
      },
      summary: '/ get-images',
      description: 'Gets a list of images',
      method: 'GET',
      path: '/get-images',
      query: z.object({
        page: z.number().optional().default(1),
        perPage: z.number().optional().default(30),
        generateId: z.string().uuid().optional(),
        searchTerm: z.string().optional(),
        styleType: z.string().optional(),
        style: z.string().optional(),
        size: z.string().optional(),
        aspectRatio: z.string().optional(),
        excludeId: z.string().uuid().optional(),
        useOrs: z.boolean().optional(),
      }),
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              total: z.number(),
              images: z.array(
                z.object({
                  id: z.string().uuid(),
                  aspect_ratio: z.string(),
                  generate_id: z.string().uuid(),
                  caption: z.string(),
                  detailed_caption: z.string(),
                  more_detailed_caption: z.string(),
                  object_detection: z.record(
                    z.string(),
                    z.array(z.object({ x1: z.number(), x2: z.number(), y1: z.number(), y2: z.number() })),
                  ),
                  prompt_grounded_object_detection: z.record(
                    z.string(),
                    z.array(z.object({ x1: z.number(), x2: z.number(), y1: z.number(), y2: z.number() })),
                  ),
                  height: z.number(),
                  is_generated_through_api: z.boolean(),
                  is_image_guided: z.boolean(),
                  is_private: z.boolean(),
                  is_hidden: z.boolean(),
                  nsfw_detected: z.boolean(),
                  nsfw_text_detected: z.boolean(),
                  png_s3_key: z.string(),
                  webp_thumb_s3_key: z.string(),
                  jpg_s3_key: z.string(),
                  webp_s3_key: z.string(),
                  svg_s3_key: z.string(),
                  seed: z.string(),
                  orientation_type: z.string(),
                  size_type: z.string(),
                  size: z.string(),
                  style_type: z.string(),
                  style: z.string(),
                  user_prompt: z.string(),
                  user_negative_prompt: z.string(),
                  width: z.number(),
                  updated_at: z.string().datetime(),
                  created_at: z.string().datetime(),
                  num_ratings: z.number().nullable(),
                  rating: z.number().nullable(),
                  num_downloads: z.number().nullable(),
                  num_shares: z.number().nullable(),
                  user_rating: z
                    .object({
                      rating: z.number().min(1).max(5).openapi({
                        description: 'The calculated rating from the community, from `1` to `5`, for this image.',
                      }),
                      max_rating: z.number().min(5).max(5).openapi({
                        description: 'The maximum rating that was possible for this `rating` when it was submitted.',
                      }),
                      updated_at: z.string().datetime().openapi({
                        description: 'The date and time at which the image rating was last updated.',
                      }),
                      created_at: z.string().datetime().openapi({
                        description: 'The date and time at which the image rating was created.',
                      }),
                    })
                    .nullable(),
                }),
              ),
            }),
          })
          .openapi({
            title: 'Get Images',
            description: 'Get a list of images',
          }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
