'use client'

import Link from 'next/link'
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faPencil } from '@fortawesome/free-solid-svg-icons/faPencil'
import { useAppSelector } from '@/lib/store'
import { getCurrentUserIsLoggedIn } from '@/lib/features/currentUser/currentUserSlice'
import { NUM_FREE_DAILY_CREDITS } from '../../../../../../../lib/constants/lib'
import Newsletter from './Newsletter'

interface Props {
  bgDark?: boolean
}

export default function Cta({ bgDark }: Props): React.ReactElement | null {
  const isLoggedIn = useAppSelector(getCurrentUserIsLoggedIn)

  return (
    <section className={bgDark ? 'bg-neutral-100 dark:bg-neutral-900' : ''}>
      <div className="mx-auto max-w-screen-xl px-4 py-8 text-center lg:px-12 lg:py-16">
        <Link
          href="/pricing"
          className="text-primary-text dark:text-primary-text-dark border-border dark:border-border-dark mb-7 inline-flex items-center justify-between rounded-full border bg-white p-1 pr-4 text-sm transition-all hover:bg-neutral-50 dark:bg-neutral-700 dark:hover:bg-neutral-600"
          aria-label="Pricing options"
        >
          <span className="bg-primary dark:bg-primary-dark text-primary-contrast-primary-text dark:text-primary-dark-contrast-primary-text mr-3 rounded-full px-4 py-1.5 text-xs">
            New
          </span>
          <span className="text-sm font-medium">Improved models! Get your membership!</span>
          <svg className="ml-2 size-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </Link>
        <p className="text-primary-text dark:text-primary-text-dark mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl lg:text-6xl">
          Enhance your creativity!
        </p>
        <p className="text-secondary-text dark:text-secondary-text-dark mb-8 text-lg font-normal sm:px-16 lg:text-xl xl:px-48">
          Increase your productivity and tap into new levels of potential. With PicTrix, you'll get more done in less time, discover
          innovative ways to achieve your goals, and elevate your work to the next level.
        </p>
        <div className="mb-12 flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-x-4 sm:space-y-0">
          <Link href="/pricing" className="button-large button-primary inline-flex">
            Subscribe
            <svg className="size-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </Link>
          {!isLoggedIn && (
            <Link href="/register" className="button-large button-default flex sm:flex-row-reverse">
              Register
              <FontAwesomeIcon icon={faPencil} className="text-primary-text dark:text-primary-text-dark size-4" />
            </Link>
          )}
        </div>
        <div className="mx-auto mb-8 max-w-96">
          <Newsletter />
        </div>
        <div className="text-primary-text dark:text-primary-text-dark mx-auto px-4 text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-36">
          <div className="text-primary-text dark:text-primary-text-dark font-medium uppercase">WE OFFER</div>
          <div className="text-secondary-text dark:text-secondary-text-dark mt-4 flex flex-col flex-wrap items-center justify-center gap-4 sm:flex-row sm:justify-between">
            <span className="flex items-center text-sm font-medium sm:text-lg">
              <FontAwesomeIcon icon={faCheckCircle} className="text-success dark:text-success-dark ml-4 mr-2 size-4" />
              100+ predefined styles
            </span>
            <span className="flex items-center text-sm font-medium sm:text-lg">
              <FontAwesomeIcon icon={faCheckCircle} className="text-success dark:text-success-dark ml-4 mr-2 size-4" />
              Make your own style
            </span>
            <span className="flex items-center text-sm font-medium sm:text-lg">
              <FontAwesomeIcon icon={faCheckCircle} className="text-success dark:text-success-dark ml-4 mr-2 size-4" />
              Color SVG's
            </span>
            <span className="flex items-center text-sm font-medium sm:text-lg">
              <FontAwesomeIcon icon={faCheckCircle} className="text-success dark:text-success-dark ml-4 mr-2 size-4" />
              Typography
            </span>
            <span className="flex items-center text-sm font-medium sm:text-lg">
              <FontAwesomeIcon icon={faCheckCircle} className="text-success dark:text-success-dark ml-4 mr-2 size-4" />
              Many sizes / aspect ratios
            </span>
            <span className="flex items-center text-sm font-medium sm:text-lg">
              <FontAwesomeIcon icon={faCheckCircle} className="text-success dark:text-success-dark ml-4 mr-2 size-4" />
              Unlimited downloads
            </span>
            <span className="flex items-center text-sm font-medium sm:text-lg">
              <FontAwesomeIcon icon={faCheckCircle} className="text-success dark:text-success-dark ml-4 mr-2 size-4" />
              {`${NUM_FREE_DAILY_CREDITS} free image generations per day`}
            </span>
            <span className="flex items-center text-sm font-medium sm:text-lg">
              <FontAwesomeIcon icon={faCheckCircle} className="text-success dark:text-success-dark ml-4 mr-2 size-4" />
              Guide AI with your own image
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}
