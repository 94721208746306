'use client'

import Link from 'next/link'
import Image from 'next/image'
import * as React from 'react'
import { useTheme } from 'next-themes'
import { useAppSelector } from '@/lib/store'
import { getCurrentUser, getCurrentUserIsLoggedIn } from '@/lib/features/currentUser/currentUserSlice'
import Newsletter from '@/app/components/Footer/Newsletter'

export default function Footer(): React.ReactElement {
  const user = useAppSelector(getCurrentUser)
  const userIsLoggedIn = useAppSelector(getCurrentUserIsLoggedIn)

  const [apiUrl, setApiUrl] = React.useState<string>(`${process.env['NEXT_PUBLIC_PICTRIX_API_URL']}/v1/docs`)
  const [currentTheme, setCurrentTheme] = React.useState<string>('light')

  const { systemTheme, theme } = useTheme()

  React.useEffect(() => {
    setCurrentTheme((theme === 'system' ? systemTheme : theme) ?? 'light')
  }, [systemTheme, theme])

  const socialMediaProfileUrls = {
    FACEBOOK: 'https://www.facebook.com/pictrixai',
    X: 'https://x.com/PicTrixAI',
    INSTAGRAM: 'https://www.instagram.com/pictrixai/',
    PINTEREST: 'https://www.pinterest.com/pictrixai',
    LINKED_IN: 'https://www.linkedin.com/company/pictrixai',
  }

  React.useEffect(() => {
    setApiUrl(`${process.env['NEXT_PUBLIC_PICTRIX_API_URL']}/v1/docs?theme=${currentTheme === 'dark' ? 'dark' : 'light'}`)
  }, [currentTheme])

  const currentYear = new Date().getFullYear()

  return (
    <footer className="sticky top-[100vh] bg-neutral-100 dark:bg-neutral-900">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="lg:flex lg:justify-between">
          <div className="mb-6 md:mb-0">
            <Link href="/" className="inline-flex items-center">
              <Image src="/images/logo.svg" className="dark:invert" width={158} height={40} alt="PicTrix Logo" loading="lazy" />
            </Link>
            <Newsletter />
          </div>
          <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 sm:gap-6">
            <div>
              <h3 className="text-primary-text dark:text-primary-text-dark mb-6 text-sm font-semibold uppercase">Resources</h3>
              <ul className="text-secondary-text dark:text-secondary-text-dark text-sm font-medium">
                <li className="mb-4">
                  <Link
                    href="/"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    Home
                  </Link>
                </li>
                {userIsLoggedIn && (
                  <li className="mb-4">
                    <Link
                      href="/generate"
                      className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                    >
                      Generate
                    </Link>
                  </li>
                )}
                <li className="mb-4">
                  <Link
                    href="/browse"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    Browse
                  </Link>
                </li>
                {(!userIsLoggedIn || !user?.user_membership) && (
                  <li className="mb-4">
                    <Link
                      href="/pricing"
                      className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                    >
                      Pricing
                    </Link>
                  </li>
                )}
                <li className="mb-4">
                  <Link
                    href={apiUrl}
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    API
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    href="/contact"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    Contact us
                  </Link>
                </li>
                {!userIsLoggedIn && (
                  <li className="hover:text-primary dark:hover:text-primary-dark mb-4">
                    <Link href="/login">Sign in</Link>
                  </li>
                )}
                {!userIsLoggedIn && (
                  <li className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors">
                    <Link href="/register">Register</Link>
                  </li>
                )}
              </ul>
            </div>
            <div>
              <h3 className="text-primary-text dark:text-primary-text-dark mb-6 text-sm font-semibold uppercase">Follow us</h3>
              <ul className="text-secondary-text dark:text-secondary-text-dark text-sm font-medium">
                <li className="mb-4">
                  <Link
                    href={socialMediaProfileUrls.FACEBOOK}
                    target="_blank"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    Facebook
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    href={socialMediaProfileUrls.X}
                    target="_blank"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    X / Twitter
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    href={socialMediaProfileUrls.INSTAGRAM}
                    target="_blank"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    Instagram
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    href={socialMediaProfileUrls.PINTEREST}
                    target="_blank"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    Pinterest
                  </Link>
                </li>
                <li>
                  <Link
                    href={socialMediaProfileUrls.LINKED_IN}
                    target="_blank"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    LinkedIn
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-primary-text dark:text-primary-text-dark mb-6 text-sm font-semibold uppercase">Legal</h3>
              <ul className="text-secondary-text dark:text-secondary-text-dark text-sm font-medium">
                <li className="mb-4">
                  <Link
                    href="/community-guidelines"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    Community guidelines
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    href="/privacy-policy"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    Privacy policy
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    href="/cookie-policy"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    Cookie policy
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    href="/terms-and-conditions"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    Terms &amp; conditions
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    href="/trademark-disclosure"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    Trademark disclosure
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    href="/dmca"
                    className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
                  >
                    DMCA
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="dark:border-border-dark my-6 border-neutral-400 sm:mx-auto lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-secondary-text dark:text-secondary-text-dark text-sm sm:text-center">
            {currentYear !== 2024 ? `© 2024-${currentYear} ` : `© ${currentYear} `}
            <Link
              href="/"
              className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
            >
              PicTrix
            </Link>
            . All Rights Reserved.
          </span>
          <div className="mt-4 flex sm:mt-0 sm:justify-center">
            <Link
              href={socialMediaProfileUrls.FACEBOOK}
              target="_blank"
              className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover transition-colors"
            >
              <svg className="size-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 320 512">
                <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
              </svg>
              <span className="sr-only">Facebook page</span>
            </Link>
            <Link
              href={socialMediaProfileUrls.X}
              target="_blank"
              className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover ms-5 transition-colors"
            >
              <svg className="size-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
              </svg>
              <span className="sr-only">X page</span>
            </Link>
            <Link
              href={socialMediaProfileUrls.X}
              target="_blank"
              className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover ms-5 transition-colors"
            >
              <svg className="size-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                <path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
              </svg>
              <span className="sr-only">Twitter page</span>
            </Link>
            <Link
              href={socialMediaProfileUrls.INSTAGRAM}
              target="_blank"
              className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover ms-5 transition-colors"
            >
              <svg className="size-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg>
              <span className="sr-only">Instagram page</span>
            </Link>
            <Link
              href={socialMediaProfileUrls.PINTEREST}
              target="_blank"
              className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover ms-5 transition-colors"
            >
              <svg className="size-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 384 512">
                <path d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z" />
              </svg>
              <span className="sr-only">Pinterest page</span>
            </Link>
            <Link
              href={socialMediaProfileUrls.LINKED_IN}
              target="_blank"
              className="text-secondary-text dark:text-secondary-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover ms-5 transition-colors"
            >
              <svg className="size-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
                <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
              </svg>
              <span className="sr-only">LinkedIn page</span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
