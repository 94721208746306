'use client'

import '@/app/style/globals.scss'
import * as React from 'react'
import { useSnackbar } from 'notistack'
import Link from 'next/link'
import { useAppDispatch, useAppSelector } from '@/lib/store'
import { getToken } from '@/lib/features/token/tokenSlice'
import {
  getGeneratorSettingsGeneratingStatus,
  getGeneratorSettingsLastGeneratedImage,
  getGeneratorSettingsGenerationStartedAt,
  getGeneratorSettingsIsLoaded,
} from '@/lib/features/generatorSettings/generatorSettingsSlice'
import {
  EnumImageGenerationStatus,
  type EnumMembershipPeriod,
  type EnumMembershipType,
} from '../../../../../../lib/database/lib/generated/enums'
import { getCurrentUser } from '@/lib/features/currentUser/currentUserSlice'
import type { Image, User } from '@/types/entities'
import { type DataUpdatedTypes } from '../../../../../../lib/constants/lib'
import type WebsocketService from '@/services/websocket'
import { revalidatePath } from '@/app/server-actions/revalidate'

interface Props {
  children: React.ReactNode
}

export default function ClientLayout({ children }: Props): React.ReactElement {
  const dispatch = useAppDispatch()
  const token = useAppSelector(getToken)
  const user = useAppSelector(getCurrentUser)
  const generationStartedAt = useAppSelector(getGeneratorSettingsGenerationStartedAt)
  const generatingStatus = useAppSelector(getGeneratorSettingsGeneratingStatus)
  const areGeneratorSettingsLoaded = useAppSelector(getGeneratorSettingsIsLoaded)
  const lastGeneratedImage = useAppSelector(getGeneratorSettingsLastGeneratedImage)

  const [connectWebsocket, setConnectWebsocket] = React.useState<boolean>(false)

  const userRef = React.useRef<User | null>(user)
  const generationStartedAtRef = React.useRef<string | null>(generationStartedAt)
  const generatingStatusRef = React.useRef<keyof typeof EnumImageGenerationStatus | null>(generatingStatus)
  const lastGeneratedImageRef = React.useRef<Image | null>(lastGeneratedImage)

  React.useEffect(() => {
    userRef.current = user
  }, [user])

  React.useEffect(() => {
    generationStartedAtRef.current = generationStartedAt
  }, [generationStartedAt])

  React.useEffect(() => {
    generatingStatusRef.current = generatingStatus
  }, [generatingStatus])

  React.useEffect(() => {
    lastGeneratedImageRef.current = lastGeneratedImage
  }, [lastGeneratedImage])

  const { enqueueSnackbar } = useSnackbar()

  const resetGeneratingStatusIn2Secs = React.useCallback((): void => {
    setTimeout(() => {
      if (generatingStatusRef.current !== null) {
        void (async () => {
          const { setGeneratorSettingsGeneratingStatus } = await import('@/lib/features/generatorSettings/generatorSettingsSlice')

          dispatch(setGeneratorSettingsGeneratingStatus(null))
        })()
      }
    }, 2000)
  }, [dispatch])

  const updateGeneratingStatus = React.useCallback(
    async (
      updatedUser: User | null,
      newStatus: keyof typeof EnumImageGenerationStatus | null,
      newImage?: Image | null,
      errorMessage?: string | null,
    ) => {
      const { setGeneratorSettingsGeneratingStatus } = await import('@/lib/features/generatorSettings/generatorSettingsSlice')

      dispatch(setGeneratorSettingsGeneratingStatus(newStatus))

      const { isActiveGeneratingStatus } = await import('@/lib/utils')

      if (isActiveGeneratingStatus(newStatus)) {
        return
      }

      resetGeneratingStatusIn2Secs()

      const Snackbar = (await import('@/app/components/Snackbar')).default

      if (newStatus === EnumImageGenerationStatus.COMPLETED) {
        enqueueSnackbar(
          <Snackbar>
            <p>
              Your image has been generated!<span>&nbsp;</span>
              {newImage && (
                <Link href={`/image/${newImage.id}`} className="button-small button-snackbar-success-action ml-4 inline sm:ml-8">
                  View
                </Link>
              )}
            </p>
          </Snackbar>,
          { variant: 'success', autoHideDuration: 10000 },
        )

        if (newImage) {
          await revalidatePath(`/image/${newImage.id}`)
        }

        if (process.env['NEXT_PUBLIC_PICTRIX_STAGE'] === 'prod') {
          const { sendGTMEvent } = await import('@next/third-parties/google')

          sendGTMEvent({
            event: 'image_generate',
            user_id: updatedUser?.id ?? userRef.current?.id,
            image_id: newImage?.id,
          })

          if (!updatedUser?.user_membership) {
            const { clientApiService } = await import('@/services/client-api-service')

            const { body: creditsLeftBody, status: creditsLeftStatus } = await clientApiService.getNonMemberCreditsLeftToday.query({
              extraHeaders: {
                authorization: token ? `Bearer ${token}` : undefined,
              },
            })

            if (creditsLeftStatus === 200 && creditsLeftBody.data.numCreditsLeftToday === 0) {
              sendGTMEvent({
                event: 'used_all_daily_free_credits',
                user_id: updatedUser?.id ?? userRef.current?.id,
              })
            }
          }
        }
      } else if (newStatus === EnumImageGenerationStatus.CANCELLED) {
        enqueueSnackbar(
          <Snackbar>
            <p>Your image generation has been cancelled.</p>
          </Snackbar>,
          { variant: 'info' },
        )
      } else if (newStatus === EnumImageGenerationStatus.FAILED) {
        enqueueSnackbar(
          <Snackbar>
            <p>Your image generation has failed.</p>
            {!!errorMessage && <p>{errorMessage}.</p>}
          </Snackbar>,
          { variant: 'error' },
        )
      } else if (newStatus === EnumImageGenerationStatus.TIMED_OUT) {
        enqueueSnackbar(
          <Snackbar>
            <p>Your image generation has timed out.</p>
            {!!errorMessage && <p>{errorMessage}.</p>}
          </Snackbar>,
          { variant: 'error' },
        )
      }
    },
    [dispatch, enqueueSnackbar, resetGeneratingStatusIn2Secs, token],
  )

  const updateLastGeneratedImage = React.useCallback(
    async (lastGenerateId: string): Promise<Image | null> => {
      const { clientApiService } = await import('@/services/client-api-service')

      const { body: getImagesBody, status: getImagesStatus } = await clientApiService.getImages.query({
        query: {
          generateId: lastGenerateId,
        },
        extraHeaders: {
          authorization: `Bearer ${token}`,
        },
      })

      const newGeneratedImage = getImagesStatus === 200 ? getImagesBody.data.images[0] ?? null : null

      if (newGeneratedImage) {
        const { setGeneratorSettingsLastGeneratedImage } = await import('@/lib/features/generatorSettings/generatorSettingsSlice')

        dispatch(setGeneratorSettingsLastGeneratedImage(newGeneratedImage ?? null))
      }

      return newGeneratedImage
    },
    [dispatch, token],
  )

  React.useEffect(() => {
    if (!areGeneratorSettingsLoaded) {
      return
    }

    void (async () => {
      const { DateTime } = await import('luxon')

      const mostRecentImageGenerationStatus = userRef.current?.user_image_generations?.length
        ? userRef.current.user_image_generations.reduce((a, b) => {
            if (a === null) {
              return b
            }

            return DateTime.fromISO(a.created_at) > DateTime.fromISO(b.created_at) ? a : b
          })?.status
        : null

      const { isActiveGeneratingStatus } = await import('@/lib/utils')

      if (!isActiveGeneratingStatus(generatingStatusRef.current)) {
        const isLastImageDifferent = userRef.current?.last_generate_id !== lastGeneratedImageRef.current?.generate_id

        if (userRef.current?.last_generate_id && isLastImageDifferent) {
          await updateLastGeneratedImage(userRef.current.last_generate_id)
        }

        resetGeneratingStatusIn2Secs()

        return
      }

      if (isActiveGeneratingStatus(generatingStatusRef.current)) {
        if (userRef.current?.last_generate_id) {
          const isFirstImage = lastGeneratedImageRef.current === null
          const isLastImageDifferent = userRef.current.last_generate_id !== lastGeneratedImageRef.current?.generate_id
          const lastGeneratedImageCameAfterLastGenerationStart =
            generationStartedAtRef.current &&
            lastGeneratedImageRef.current &&
            DateTime.fromISO(lastGeneratedImageRef.current.created_at) > DateTime.fromISO(generationStartedAtRef.current)

          if (isFirstImage || isLastImageDifferent || lastGeneratedImageCameAfterLastGenerationStart) {
            // Last generated image is newer that what's in localStorage. Must catch it up and notify completion.
            const newImage = await updateLastGeneratedImage(userRef.current.last_generate_id)

            await updateGeneratingStatus(userRef.current, EnumImageGenerationStatus.COMPLETED, newImage)

            return
          }

          // Last generated image hasn't been updated since we were last here. Just catch up the status.
          await updateGeneratingStatus(userRef.current, mostRecentImageGenerationStatus)
        } else {
          // User has no last generated image. Must catch up the status to whatever's most recent.
          await updateGeneratingStatus(userRef.current, mostRecentImageGenerationStatus)
        }

        return
      }

      const generationInProgress = userRef.current?.user_image_generations?.find((imageGeneration) =>
        isActiveGeneratingStatus(imageGeneration.status),
      )

      if (generationInProgress) {
        await updateGeneratingStatus(userRef.current, generationInProgress.status)
      }
    })()
  }, [areGeneratorSettingsLoaded, dispatch, resetGeneratingStatusIn2Secs, updateGeneratingStatus, updateLastGeneratedImage])

  React.useEffect(() => {
    setTimeout(() => {
      setConnectWebsocket(true)
    }, 2000)

    if (user) {
      localStorage.setItem(
        'userInfo',
        JSON.stringify({
          email: user.email,
          firstName: user.first_name,
          middleName: user.middle_name,
          lastName: user.last_name,
          pictureUrl: user.picture_url,
        }),
      )
    } else {
      localStorage.removeItem('userInfo')
    }
  }, [user])

  React.useEffect(() => {
    if (!connectWebsocket || !token) {
      return () => {}
    }

    const onMessage = async (dataUpdated: DataUpdatedTypes, data: unknown): Promise<void> => {
      await (
        await import('@sentry/nextjs')
      ).startSpan(
        {
          name: 'Get Current User (Webhook Update)',
        },
        async () => {
          const { clientApiService } = await import('@/services/client-api-service')

          const { body, status } = await clientApiService.getCurrentUser.query({
            headers: {
              authorization: `Bearer ${token}`,
            },
          })

          if (status !== 200) {
            console.error(body.message)

            return
          }

          const newUser = body.data

          const { setCurrentUser, setLastDataUpdated } = await import('@/lib/features/currentUser/currentUserSlice')

          dispatch(setCurrentUser(newUser))
          dispatch(setLastDataUpdated(dataUpdated))

          const { DataUpdated } = await import('../../../../../../lib/constants/lib')

          if (dataUpdated === DataUpdated.USER_IMAGE_GENERATION) {
            const {
              status: newStatus,
              errorMessage: newErrorMessage,
              images: newImages,
            } = data as {
              status: keyof typeof EnumImageGenerationStatus
              errorMessage?: string | null
              imageGenerationId: string
              images: Image[] | null
            }

            if (newStatus === EnumImageGenerationStatus.COMPLETED) {
              if (newImages?.[0]) {
                const { setGeneratorSettingsLastGeneratedImage } = await import('@/lib/features/generatorSettings/generatorSettingsSlice')

                dispatch(setGeneratorSettingsLastGeneratedImage(newImages[0]))
              }
            }

            await updateGeneratingStatus(newUser, newStatus, newImages?.[0], newErrorMessage)
          }

          // Check all events since there's a delay before DB lambda trigger
          if (
            ([DataUpdated.USER, DataUpdated.USER_MEMBERSHIP, DataUpdated.USER_CREDITS] as string[]).includes(dataUpdated) &&
            newUser &&
            userRef.current
          ) {
            const { DateTime } = await import('luxon')

            const isNewPurchase = !userRef.current.user_membership?.end_at && newUser.user_membership?.end_at
            const isRenewal =
              userRef.current.user_membership?.end_at &&
              newUser.user_membership?.end_at &&
              DateTime.fromISO(newUser.user_membership.end_at) > DateTime.fromISO(userRef.current.user_membership.end_at)

            if (isNewPurchase || isRenewal) {
              const Snackbar = (await import('@/app/components/Snackbar')).default

              enqueueSnackbar(
                <Snackbar>
                  <p className="font-extrabold">Your membership has been {isRenewal ? 'renewed' : 'applied'}!</p>
                  <p>You can now access all your membership features and start generating images immediately.</p>
                </Snackbar>,
                {
                  variant: 'success',
                  autoHideDuration: 10000,
                  className: 'animate-bounce',
                },
              )

              if (process.env['NEXT_PUBLIC_PICTRIX_STAGE'] === 'prod') {
                const newestTransaction = newUser?.user_transactions?.length
                  ? newUser?.user_transactions.reduce((a, b) => {
                      if (a === null) {
                        return b
                      }

                      return DateTime.fromISO(a.created_at) > DateTime.fromISO(b.created_at) ? a : b
                    })
                  : null

                if (newestTransaction) {
                  const { sendGTMEvent } = await import('@next/third-parties/google')
                  const { MEMBERSHIP_PERIOD_NAME, MEMBERSHIP_TYPE_NAME } = await import('@/lib/constants')

                  sendGTMEvent({ ecommerce: null })
                  sendGTMEvent({
                    event: 'purchase',
                    user_id: userRef.current.id,
                    ecommerce: {
                      transaction_id: newestTransaction.stripe_subscription_id,
                      value: newestTransaction.selling_price ? Number((newestTransaction.selling_price / 100).toFixed(2)) : null,
                      tax: 0,
                      shipping: 0,
                      currency: newestTransaction.currency?.toUpperCase(),
                      coupon: newestTransaction.stripe_promotion_code,
                      items: [
                        {
                          item_id: newestTransaction.stripe_price_id,
                          item_name: `${MEMBERSHIP_TYPE_NAME[newestTransaction.membership_type as keyof typeof EnumMembershipType]} Membership`,
                          coupon: newestTransaction.stripe_promotion_code,
                          discount: newestTransaction.discount_amount,
                          index: 0,
                          item_variant: `${MEMBERSHIP_PERIOD_NAME[newestTransaction.membership_period as keyof typeof EnumMembershipPeriod]}`,
                          price: newestTransaction.selling_price ? Number((newestTransaction.selling_price / 100).toFixed(2)) : null,
                          promotion_id: newestTransaction.stripe_promo_id,
                          promotion_name: newestTransaction.stripe_coupon_name,
                          quantity: 1,
                        },
                      ],
                    },
                  })
                }
              }
            }
          }
        },
      )
    }

    let websocketService: ReturnType<typeof WebsocketService.subscribe>

    void (async () => {
      websocketService = (await import('@/services/websocket')).default.subscribe(token ?? '', onMessage)
    })()

    return () => {
      if (websocketService) {
        websocketService.destroy()
      }
    }
  }, [connectWebsocket, dispatch, enqueueSnackbar, token, updateGeneratingStatus, updateLastGeneratedImage])

  return <main>{children}</main>
}
